import React, { useState, useEffect } from 'react';
import './Toolbar.css';

const Toolbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);

      const sections = ['home', 'project', 'whoarewe', 'services', 'contact'];
      const current = sections.find((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });

      if (current) {
        setActiveSection(current);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={`toolbar ${scrolled ? 'scrolled' : ''}`}>
      <div className="toolbar-logo">
        <a href="#home" aria-label="Home">
          <img src="/logo.png" alt="A2 BAR Logo" />
        </a>
      </div>

      {/* Hamburger Button (responsive toggle) */}
      <button
        className={`hamburger ${isMenuOpen ? 'open' : ''}`}
        onClick={toggleMenu}
        aria-label="Menu Toggle"
        aria-expanded={isMenuOpen}
      >
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </button>

      {/* Navigation for Desktop and Mobile */}
      <nav className={`toolbar-nav ${isMenuOpen ? 'active' : ''}`}>
        <ul>
          {[
            { id: 'home', text: 'Accueil' },
            { id: 'services', text: 'Nos Services' },
            { id: 'project', text: 'Notre Projet' },
            { id: 'whoarewe', text: 'Qui sommes-nous ?' },
            { id: 'contact', text: 'Contact' },
          ].map(({ id, text }) => (
            <li key={id}>
              <a
                href={`#${id}`}
                className={activeSection === id ? 'active' : ''}
                onClick={() => {
                  setIsMenuOpen(false); // Close menu on click
                  setActiveSection(id);
                }}
              >
                {text}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Toolbar;
