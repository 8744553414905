import React, { useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import Toolbar from "./Toolbar";
import "./App.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";

library.add(
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faShareAlt,
  faInstagram,
  faLinkedin
);

const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


const testimonials = [
  {
    image: "/COLLABORATION-W_MATISSE-L_ARTISAN-DE-LA-VIANDE-15_10_2023.webp",
    caption: "COLLABORATION AVEC MATISSE - L'ARTISAN DE LA VIANDE - 15 OCTOBRE 2023",
  },
  {
    image: "/ÉVÉNEMENT GROUPE PASSION AUTOMOBILE - 25_04_2024.webp",
    caption: "EVENEMENT GROUPE PASSION AUTOMOBILE - 25 AVRIL 2024",
  },
  {
    image: "/ÉVÉNEMENT HERMES - 04_12_2023.webp",
    caption: "EVENEMENT HERMES - 4 DECEMBRE 2023",
  },
  {
    image: "/ÉVÉNEMENT LAMBORGHINI LYON - 06_06_2024.webp",
    caption: "EVENEMENT LAMBORGHINI LYON - 6 JUIN 2024",
  },
  {
    image: "/ÉVÉNEMENT-AVERY-13_12_2024.webp",
    caption: "EVENEMENT AVERY - 13 DECEMBRE 2024",
  },
  {
    image: "/ÉVÉNEMENT LAMBORGHINI LYON - 20_09_2024.webp",
    caption: "EVENEMENT LAMBORGHINI LYON - 20 SEPTEMBRE 2024",
  },
  {
    image: "/ÉVÉNEMENT-BENTLEY-07_03_2024.webp",
    caption: "EVENEMENT BENTLEY - 7 MARS 2024",
  },
  {
    image: "/ÉVÉNEMENT-PRIVÉE-14_10_2023.webp",
    caption: "EVENEMENT PRIVE - 14 OCTOBRE 2023",
  },
  {
    image: "/ÉVÉNEMENT-FESTIVAL-LMG-09_04_2024.webp",
    caption: "EVENEMENT FESTIVAL LMG - 9 AVRIL 2024",
  },
  {
    image: "/ÉVÉNEMENT-OZON-LA-COM-13_06_2024.webp",
    caption: "EVENEMENT OZON LA COM - 13 JUIN 2024",
  },
  {
    image: "/ÉVÉNEMENT-BENTLEY-LYON-19_11_2024-.webp",
    caption: "EVENEMENT BENTLEY LYON - 19 NOVEMBRE 2024",
  },
  {
    image: "/ÉVÉNEMENT-BENTLEY-07_03_2024.webp",
    caption: "EVENEMENT BENTLEY - 7 MARS 2024",
  },
  {
    image: "/ÉVÉNEMENT-PRIVÉE-02_06_2023.webp",
    caption: "EVENEMENT PRIVE - 2 JUIN 2023",
  },

];

const TestimonialSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleNext = useCallback(() => {
    if (isTransitioning) return; // Prevent multiple clicks
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
      setIsTransitioning(false);
    }, 300);
  }, [isTransitioning]);
  
  const handlePrev = () => {
    if (isTransitioning) return; // Prevent multiple clicks
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex(
        (prevIndex) =>
          (prevIndex - 1 + testimonials.length) % testimonials.length
      );
      setIsTransitioning(false);
    }, 300);
  };
  

  return (
    <section className="trust-section">
      <h2 style={{ textAlign: "center", color: "#fff" }}>Ils nous font confiance</h2>
      
      <div className="testimonial-carousel">
        <button className="carousel-button left" onClick={handlePrev}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        <div className="testimonial-container">
          <div className="testimonial-image-wrapper">
            <img
              src={testimonials[currentIndex].image}
              alt={testimonials[currentIndex].caption}
              className="testimonial-image"
            />
          </div>
          <div className="testimonial-caption">
            {testimonials[currentIndex].caption}
          </div>
        </div>

        <button className="carousel-button right" onClick={handleNext}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </section>
  );
};




function App() {
  const handleScrollToServices = (event) => {
    event.preventDefault();
    const servicesSection = document.getElementById("services");
    const toolbarHeight = document.querySelector(".toolbar").offsetHeight;
    const topOffset = servicesSection.offsetTop - toolbarHeight;

    window.scrollTo({
      top: topOffset,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <Toolbar />
      <main>
        <div className="hero-section" id="home">
        <video
            autoPlay
            loop
            muted
            playsInline={isMobile()}
            className="background-video"
          >            <source src="/background.webm" type="video/webm" />
            Your browser does not support the video tag.
          </video>
          <div className="hero-content">
            <p className="small-text">Découvrez</p>
            <h1 className="main-text">A2 BAR</h1>
            <p className="sub-text">Le service d'événementiel mobile</p>
            <a
              href="#project"
              className="services-button"
              onClick={handleScrollToServices}
            >
              Nous découvrir
            </a>
          </div>
        </div>

        <section className="services-section" id="services">
          <h2>Nos services</h2>
          <div className="services-container">
            <div className="service-box">
              <img src="/carte.webp" alt="Service 3" />
              <h3>Une carte de cocktails création selon vos envies</h3>
              <p>
                Découvrez notre sélection de cocktails création, élaborés avec
                des produits frais et de saison. Le but et de vous faire voyager
                à travers la mixologie sous toutes ses formes. Bien sûr, les
                grands classiques sont également au rendez-vous pour satisfaire
                toutes vos envies.
              </p>
            </div>
            <div className="service-box">
              <img src="/surmesure.webp" alt="Service 2" />
              <h3>Une offre sur mesure</h3>
              <p>
                Ajoutez une touche unique à votre événement avec nos options de
                personnalisation : impression de votre logo, date ou nom
                directement sur les cocktails ou les glaçons. Une manière
                originale de surprendre et d’émerveiller vos convives !
              </p>
            </div>
            <div className="service-box">
              <img src="/partpro.webp" alt="Service 1" />
              <h3>Particuliers & Professionnels</h3>
              <p>
                Avec nos 3 bars mobiles (2 d’un mètre et 1 de deux mètres), nous
                nous adaptons à tous vos événements : mariage, anniversaire,
                baptême, soirée d’entreprise ou simplement une dégustation entre
                amis. Offrez-vous le plaisir de savourer des cocktails,
                directement chez vous ou sur votre lieu de fête.
              </p>
            </div>
          </div>
        </section>

        <section className="project-section" id="project">
          <div className="project-content">
            <div className="text-content">
              <h2>Notre projet, vos objectifs, une réussite.</h2>
              <p>
                A2BAR est une entreprise lyonnaise de service d’événementiel
                mobile. Implantés en région lyonnaise, nous proposons des
                prestations sur toute la Région Auvergne-Rhône-Alpes. Nous
                mettons en avant un service de cocktails qualitatif et
                sur-mesure.
                <p>
                  Toutes les prestations sont réalisées à la demande du client.
                  Nous cherchons à nous adapter et proposer un service sur
                  mesure et réactif !
                </p>
                <p>
                  Nous sommes là pour faire vivre votre événement et l’organiser
                  à vos côtés.
                </p>
              </p>
            </div>
            <div className="image-content">
              <img src="project.webp" alt="Project" />
            </div>
          </div>
        </section>
        <section className="about-section" id="whoarewe">
          <h2>Qui sommes-nous ?</h2>
          <div className="about-content">
            <div className="about-box">
              <img src="/arthur.webp" alt="Arthur Giry" />
              <h3>
                Arthur Giry<span className="cofounder">Cofondateur</span>
              </h3>
              <p>
                A2BAR est un projet commun que j’ai avec Anatole, un ami de
                longue date avec qui je partage la passion de l’entreprenariat
                et du service d’événementiel.
              </p>
              <p>Créer, imaginer, entreprendre : voilà ce qui m’anime.</p>
            </div>
            <div className="about-box">
              <img src="/anatole.webp" alt="Anatole Uzel" />
              <h3>
                Anatole Uzel<span className="cofounder">Cofondateur</span>
              </h3>
              <p>
                A2BAR est pour moi un projet créatif liant ma passion pour la
                mixologie et une amitié de longue date. C’est cette vocation
                pour l’événementiel et l’envie de faire plaisir qui nous ont
                réunis avec Arthur.{" "}
              </p>
              <p>
                J’ai toujours aimé créer, découvrir, partager et entreprendre.
                A2BAR est le croisement de toutes ces idées et le lieu de leur
                concrétisation.
              </p>
            </div>
            <div className="about-box">
              <img src="/team.webp" alt="Une équipe, un duo" />
              <h3>Une équipe, un duo</h3>
              <p>
                Derrière A2BAR, un duo uni par une passion commune :
                révolutionner vos événements avec des expériences de mixologie
                uniques.
              </p>
              <p>
                Ensemble, nous allions créativité et expertise pour sublimer vos
                moments, que ce soit chez vous ou au cœur de votre entreprise.
              </p>
            </div>
          </div>
        </section>
        <section className="value-section">
          <div className="value-text">
            <h2>Notre petit +</h2>
            <p>
              Avec 4 ans de formation à l’Institut Paul Bocuse, spécialisé en
              spiritueux, vins et boissons pour Anatole, et un master en école
              de commerce pour Arthur, nous apportons une expertise unique à
              chacun de vos événements.
            </p>

            <p>
              Professionnalisme, prestance et sens du service sont nos
              maîtres-mots.
            </p>

            <p>
              Déjà habitués à relever des défis entrepreneuriaux, nous aimons
              créer et réaliser chaque détail artisanalement. Avec A2BAR, nous
              transportons notre marque de fabrique jusque dans vos verres, pour
              des moments aussi uniques qu’inoubliables.
            </p>
          </div>
          <div className="value-image">
            <img
              src="/values.webp"
              alt="Evénement Bentley - Lyon - 7 mars 2024"
            />
          </div>
        </section>

        <TestimonialSection />

        <section className="contact-section" id="contact">
          <h2>Contactez-nous</h2>
          <h3>
            Envie de délicieux cocktails ou d’une touche exotique pour votre
            événement ? A2BAR Event, l’entreprise lyonnaise de cocktail par
            excellence, est là pour vous !
          </h3>
          <h3>
            Contactez-nous pour créer ensemble un devis personnalisé et faire de
            votre moment un succès inoubliable.
          </h3>
          <div className="contact-info">
            <div className="contact-box">
              <div className="contact-left">
                <div className="contact-item">
                  <p className="text">
                    13 Chemin de la Bruyère, 69570 Dardilly, France
                  </p>
                </div>
                <div className="contact-item">
                  <a
                    href="mailto:a2barevent@gmail.com?subject=Demande%20de%20renseignements%20A2BAR"
                    className="text"
                  >
                    a2barevent@gmail.com
                  </a>{" "}
                </div>
                <div className="contact-item">
                  <p className="text">Arthur : +33 7 81 70 26 48</p>
                  <p className="text">Anatole : +33 6 95 97 97 90</p>
                </div>
              </div>
              <div className="vertical-line"></div>
              <div className="contact-right">
                <p className="text">Nos réseaux sociaux</p>
                <div className="social-media-icons">
                  <a
                    href="https://www.instagram.com/a2barevent/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/a2-bar/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
